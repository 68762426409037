import { Button, ButtonGroup, Container } from '@material-ui/core'
import React, { Component } from 'react'
import AllFishRanked from '../AllFishRanked'
import TeamLeaderboard from '../TeamLeaderboard'

class PublicBoards extends Component {
    state = {
        selectedView: 'team'
    }
    handleTeam = () => {
        this.setState({
            selectedView: 'team'
        })
    }
    handleFish = () => {
        this.setState({
            selectedView: 'fish'
        })
    }
    render() {
        return (
            <div>
                <Container style={{"marginTop": 20, "textAlign": "center"}}>
                    <ButtonGroup>
                        <Button onClick={this.handleTeam}>Team Leaderboard</Button>
                        <Button onClick={this.handleFish}>Biggest Fish</Button>
                    </ButtonGroup>
                    {
                        this.state.selectedView === 'team'
                        ?
                        <TeamLeaderboard />
                        :
                        <AllFishRanked />
                    }
                </Container>
            </div>
        )
    }
}

export default PublicBoards

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { SignIn } from '../../../auth/SignIn'
import AllFishRanked from '../AllFishRanked'
import PublicBoards from './PublicBoards'

const Public = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={PublicBoards}/>
                <Route path='/signin' component={SignIn} />
            </Switch>
        </BrowserRouter>
    )
}

export default Public

import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Navbar } from './components/nav/Navbar';
import Dashboard from './components/competitions/Dashboard';
import NewComp from './components/competitions/NewComp';
import Attendees from './components/competitions/attendees/Attendees';
import RegisterAttendee from './components/competitions/attendees/RegisterAttendee';
import AddTeam from './components/competitions/teams/AddTeam';
import Teams from './components/competitions/teams/Teams';
import Zones from './components/competitions/zones/Zones';
import AddZone from './components/competitions/zones/AddZone';
import Pegs from './components/competitions/pegs/Pegs';
import AddPeg from './components/competitions/pegs/AddPeg';
import AssignPeg from './components/competitions/pegs/AssignPeg';
import Leaderboards from './components/competitions/leaderboards/Leaderboards';
import Marshalls from './components/competitions/marshalls/Marshalls';
import AddMarshall from './components/competitions/marshalls/AddMarshall';
import TeamLeaderboard from './components/competitions/carpclassic/TeamLeaderboard';
import AllFishRanked from './components/competitions/carpclassic/AllFishRanked';
import AddCarpMarshall from './components/competitions/carpclassic/admin/AddCarpMarshall';
import Catches from './components/competitions/carpclassic/admin/catches/Catches';
import EditCarpCatch from './components/competitions/carpclassic/admin/catches/EditCarpCatch';

const AppAuthed = () => {
    return (
        <BrowserRouter>
          <div>
            <Navbar/>
            <Switch>
              <Route exact path='/' component={Dashboard}/>
              <Route path='/new-competition' component={NewComp}/>
              <Route path='/:id/attendees' component={Attendees}/>
              <Route path='/register-attendee/:id' component={RegisterAttendee}/>
              <Route path='/:id/add-team' component={AddTeam}/>
              <Route path='/:id/teams' component={Teams}/>
              <Route path='/:id/zones' component={Zones}/>
              <Route path='/:id/add-zone' component={AddZone}/>
              <Route path='/:id/pegs' component={Pegs}/>
              <Route path='/:id/add-peg' component={AddPeg}/>
              <Route path='/:id/assign-peg/:pegId/:pegNumber' component={AssignPeg}/>
              <Route path='/:id/leaderboards' component={Leaderboards}/>
              <Route path='/:id/marshalls' component={Marshalls}/>
              {/* <Route path='/:id/add-marshall' component={AddMarshall}/> */}
              <Route path='/carp-classic/teamboard' component={TeamLeaderboard}/>
              <Route path='/carp-classic/allfish' component={AllFishRanked}/>
              <Route path='/carp-classic/add-marshall' component={AddCarpMarshall}/>
              <Route path='/carpclassic/catches' component={Catches} />
              <Route path='/carpclassic/editcatch/:id' component={EditCarpCatch} />
            </Switch>
          </div>
        </BrowserRouter>
      );
}

export default AppAuthed

import { Button, Container, Dialog, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { compose } from 'redux'


const Catches = (props) => {
    const { catches } = props;
    console.log(catches);
    return (
        <div>
            <Container style={{ "marginTop": 20 }}>
                
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Team</TableCell>
                                <TableCell>Caught By</TableCell>
                                <TableCell>Species</TableCell>
                                <TableCell>Weight</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                catches && catches.map((fish) => {
                                    return (
                                        <TableRow key={fish.id}>
                                            <TableCell>{fish.teamName}</TableCell>
                                            <TableCell>{fish.caughtBy}</TableCell>
                                            <TableCell>{fish.species}</TableCell>
                                            <TableCell>{fish.weight}</TableCell>
                                            <TableCell>
                                                <Link to={`/carpclassic/editcatch/${fish.id}`}>
                                                    <Button color="primary">Edit</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        catches: state.firestore.ordered.carpCombined
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {
            collection: 'carpCombined',
            orderBy:['weight', 'desc']
        }
    ])
)(Catches)

import React, { Component } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { deleteCarpTeam } from '../../../store/actions/competitionActions'

// Material UI Components
import { DialogContent, DialogActions, Container, Card, CardContent, Typography, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, CardActions, Button, Dialog } from '@material-ui/core'

class Teams extends Component {
    state = {
        deleteOpen: false,
        selectedDoc: ''
    }
    openDeleteDialog = (docId) => {
        this.setState({
            deleteOpen: true,
            selectedDoc: docId
        })
    }
    closeDeleteDialog = () => {
        this.setState({
            deleteOpen: false,
            selectedDoc: ''
        })
    }
    confirmDelete = async () => {
        await this.props.deleteCarpTeam(this.state.selectedDoc, this.props.compId);
        this.closeDeleteDialog();
    }
    render() {
    const { compId, teams } = this.props;
    return (
            <div className="fa-comp-card">
                <Container>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Teams</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Team Name</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            teams && teams.map((team) => {
                                                return (
                                                    <TableRow key={team.id}>
                                                        <TableCell>{team.teamName}</TableCell>
                                                        <TableCell><Button onClick={(docId) => this.openDeleteDialog(team.id)} startIcon={<DeleteForeverIcon />} color="secondary">Delete</Button></TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                        <CardActions>
                            <Link to={`/${compId}/add-team`}><Button variant="contained" color="primary">Add Team</Button></Link>
                        </CardActions>
                    </Card>
                    <Dialog open={this.state.deleteOpen} onClose={this.closeDeleteDialog}>
                        <DialogContent>
                            Are you sure you want to delete this team? This action can not be undone.
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDeleteDialog}>Cancel</Button>
                            <Button startIcon={<DeleteForeverIcon />} variant="contained" color="secondary" onClick={this.confirmDelete}>Delete</Button>
                        </DialogActions>

                    </Dialog>
                </Container>
            </div>
        )
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        teams: state.firestore.ordered.teams
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCarpTeam: (docId, compId) => dispatch(deleteCarpTeam(docId, compId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        {
            collection: 'competitions',
            doc: props.compId,
            storeAs: 'teams',
            subcollections: [
                { collection: 'teams' }
            ]
        }
    ])
)(Teams)
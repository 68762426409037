import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { signOut } from '../../store/actions/authActions'
import { connect } from 'react-redux'

const SignedInLinks = (props) => {
    return (
        <ul className="right">
            <li><NavLink to='/'>Competitions</NavLink></li>
            <li><a onClick={props.signOut} href='#'>Sign Out</a></li>
        </ul>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)

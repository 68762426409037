import React, { Component } from 'react';
import { signIn } from '../../store/actions/authActions';
import { connect } from 'react-redux';
import logo from '../../img/falogobadge.png'

// Material UI Components
import { Card, CardContent, Typography, FormControl, TextField, Button, Container } from '@material-ui/core';

export class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state)
    }
    render() {
        return (
            <div className="container">
                <Container>
                    <div style={{"textAlign": "center"}}>
                        <img src={logo} />
                    </div>
                    <Card>
                        <CardContent>
                            <Typography varaint="h6">
                                Sign In
                            </Typography>
                            <form>
                                <div className="fa-input-field">
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" type="email" id="email" onChange={this.handleChange} />
                                    </FormControl>
                                </div>
                                <div className="fa-input-field">
                                    <FormControl fullWidth>
                                        <TextField variant="outlined" type="password" id="password" onChange={this.handleChange} />
                                    </FormControl>
                                </div>
                                <div className="fa-input-field">
                                    <Button variant="contained" color="primary" onClick={this.handleSubmit}>Submit</Button>
                                </div>
                                <div className="fa-input-field">
                                    {/* TODO: Add Auth Error Message */}
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default connect(
    null,
    mapDispatchToProps
)(SignIn)

import React, { Component } from 'react'
import Select from 'react-select';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { assignTeamToPeg } from '../../../store/actions/competitionActions'

// Material UI Components
import { Container, Card, CardContent, Typography, CircularProgress, Button } from '@material-ui/core'

class AssignPeg extends Component {
    state = {
        pegId: '',
        team: {}
    }
    handleSelectChange = (selectedOption, field) => {
        
        this.setState({
            [field]: selectedOption
        })
    }
    handleSubmit = async () => {
        await this.props.assignTeamToPeg(this.props.compId, this.props.pegId, this.props.pegNumber, this.state.team);
        this.props.history.push(`/${this.props.compId}/pegs/`)
    }
    render() {
        const { compId, pegId, teams, pegNumber } = this.props
        let teamArray = [];
        teams && teams.map((team) => {
             if (!team.assignedPeg) {
                const teamObject = {
                    value: team.id,
                    label: team.teamName
                }
                teamArray.push(teamObject)
             } 
            
        })
        return (
            <div className="fa-comp-card">
                <Container>
                    
                    <Typography variant="h6">Assign Peg Number: {pegNumber}</Typography>
                    {
                        teamArray.length > 0
                        ?
                        <div className="fa-input-field">
                            <Select
                                name="team"
                                options={teamArray}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Team"
                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'team')}
                            />
                        </div>
                        :
                        <CircularProgress />
                    }
                    <Button onClick={this.handleSubmit} variant="contained" color="primary">Confirm</Button>
                </Container>
                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        teams: state.firestore.ordered.teams,
        pegId: ownProps.match.params.pegId,
        pegNumber: ownProps.match.params.pegNumber
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        assignTeamToPeg: (compId, pegId, pegNumber, team) => dispatch(assignTeamToPeg(compId, pegId, pegNumber, team))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { 
            collection: 'competitions',
            doc: props.compId,
            storeAs: 'teams',
            subcollections: [
                { collection: 'teams'}
            ]
        }
    ])
)(AssignPeg)

import React from 'react';
import { Link } from 'react-router-dom';

// Material UI Components
import { Card, CardContent, Typography, FormControl, TextField, Button, CardActions } from '@material-ui/core';


export const CompetitionCard = (props) => {
    const { title, date, venue, id, format, usePegs, useZones } = props
    return (
        <Card>
            <CardContent>
                <Typography variant="h6">{title}</Typography>
                <Typography>Date: {date}</Typography>
                <Typography>Attendees: TBA</Typography>
            </CardContent>
            <CardActions>
                <Link to={`/${id}/marshalls/`}><Button color="primary">Marshalls</Button></Link>
                <Link to={`/${id}/attendees/`}><Button color="primary">Attendees</Button></Link>
                {
                    format === 'Teams' 
                    ?
                    <Link to={`/${id}/teams/`}><Button color="primary">Teams</Button></Link>
                    :
                    null
                }
                {
                    usePegs 
                    ?
                    <Link to={`/${id}/pegs/`}><Button color="primary">Pegs</Button></Link>
                    :
                    null
                }
                {
                    useZones
                    ?
                    <Link to={`/${id}/zones/`}><Button color="primary">Zones</Button></Link>
                    :
                    null
                }
                <Link to='/carpclassic/catches'><Button color="primary">Catches</Button></Link>
            </CardActions>
        </Card>
    )
    
}

import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { deleteZone } from '../../../store/actions/competitionActions'

// Material UI Components
import { Dialog, DialogContent, DialogActions, Container, Card, CardContent, Typography, TableContainer, Paper, Table, TableBody, TableRow, TableCell, TableHead, CardActions, Button } from '@material-ui/core'

// Material UI Icons
import AddIcon from '@material-ui/icons/Add';


export class Zones extends Component {
    state = {
        deleteOpen: false,
        selectedDoc: ''
    }
    openDeleteDialog = (docId) => {
        this.setState({
            deleteOpen: true,
            selectedDoc: docId
        })
    }
    closeDeleteDialog = () => {
        this.setState({
            deleteOpen: false,
            selectedDoc: ''
        })
    }
    confirmDelete = async () => {
        await this.props.deleteZone(this.state.selectedDoc, this.props.compId);
        this.closeDeleteDialog();
    }
    render() {
        const { compId, zones } = this.props
    return (
        <div className="fa-comp-card">
            <Container>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Zones</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Zone Number</TableCell>
                                        <TableCell>Zone Name</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        zones && zones.map((zone) => {
                                            return (
                                                <TableRow key={zone.id}>
                                                    <TableCell>{zone.zoneNumber}</TableCell>
                                                    <TableCell>{zone.zoneName}</TableCell>
                                                    <TableCell><Button onClick={(docId) => this.openDeleteDialog(zone.id)} startIcon={<DeleteForeverIcon />} color="secondary">Delete</Button></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <CardActions>
                        <Link to={`/${compId}/add-zone`}><Button color="primary" variant="contained"><AddIcon/></Button></Link>
                    </CardActions>
                </Card>
                <Dialog open={this.state.deleteOpen} onClose={this.closeDeleteDialog}>
                    <DialogContent>
                        Are you sure you want to delete this zone? This action can not be undone.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteDialog}>Cancel</Button>
                        <Button startIcon={<DeleteForeverIcon />} variant="contained" color="secondary" onClick={this.confirmDelete}>Delete</Button>
                    </DialogActions>

                </Dialog>
            </Container>
        </div>
    )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        zones: state.firestore.ordered.zones
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteZone: (docId, compId) => dispatch(deleteZone(docId, compId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { 
            collection: 'competitions',
            doc: props.compId,
            storeAs: 'zones',
            subcollections: [
                { collection: 'zones' }
            ]
        }
    ])
)(Zones)
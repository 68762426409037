import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { addPegToComp } from '../../../store/actions/competitionActions'
import Select from 'react-select';

// Material UI Components
import { Card, CardContent, Typography, FormControl, TextField, Button, Container, CircularProgress } from '@material-ui/core'

class AddPeg extends Component {
    state = {
        pegName: '',
        coords: '',
        zone: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = async (e, pegNumber) => {
        await this.props.addPegToComp({...this.state, pegNumber}, this.props.compId)
        this.props.history.push(`/${this.props.compId}/pegs`)
    }
    handleSelectChange = (selectedOption, field) => {
        this.setState({
            [field]: selectedOption
        })
    }
    render() {
        const { pegs, zones } = this.props
        let zonesArray = [];
        zones && zones.map((zone) => {
            const zoneObject = {
                value: zone.id,
                label: `${zone.zoneNumber} - ${zone.zoneName}`
            }
            zonesArray.push(zoneObject)
            console.log(zonesArray)
        })
        return (
            <div className="fa-comp-card">
                <Container>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Add Peg Number: { pegs ? pegs.length+1 : <CircularProgress />}</Typography>
                            <div className="fa-input-field">
                                <Select
                                    name="zone"
                                    options={zonesArray}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Zone"
                                    onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'zone')}
                                />
                            </div>
                            <div className="fa-input-field">
                                <FormControl fullWidth>
                                    <TextField variant="outlined" id="pegName" label="Peg Name (Optional)" onChange={this.handleChange} />
                                </FormControl>
                            </div>
                            <div className="fa-input-field">
                                <FormControl fullWidth>
                                    <TextField variant="outlined" id="coords" label="Paste GPS Coordinates (Optional)" onChange={this.handleChange} />
                                </FormControl>
                            </div>
                            
                            <div className="fa-input-field">
                                <Button onClick={(e) => this.handleSubmit(e, pegs.length+1)} variant="contained" color="primary">Save</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        pegs: state.firestore.ordered.pegs,
        zones: state.firestore.ordered.zones,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addPegToComp: (peg, compId) => dispatch(addPegToComp(peg, compId))     
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { 
            collection: 'competitions',
            storeAs: 'pegs',
            doc: props.compId,
            subcollections: [
                { collection: 'pegs' }
            ]
        },
        { 
            collection: 'competitions',
            storeAs: 'zones',
            doc: props.compId,
            subcollections: [
                { collection: 'zones' }
            ]
        }
    ])
)(AddPeg)

import { Avatar, Button, Card, CardHeader, CircularProgress, Container, Dialog, DialogContent, DialogTitle, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import moment from 'moment'

class TeamLeaderboard extends Component {
    state = {
        dialogOpen: false,
        dialogData: {},
        photoDialogOpen: false,
        photoDialogUrl: ''
    }
    openDialog = (data) => {
        this.setState({
            dialogOpen:true,
            dialogData: data
        })
    }
    closeDialog = () => {
        this.setState({
            dialogOpen: false,
            dialogData: {}
        })
    }
    openPhotoDialog = (url) => {
        this.setState({
            photoDialogUrl: url,
            photoDialogOpen: true
        })
    }
    closePhotoDialog = () => {
        this.setState({
            photoDialogOpen:false,
            photoDialogUrl: ''
        })
    }
    render() {
        const { carpLeaderBoard } = this.props
        let rank = 0;
        return (
            <Container style={{"marginTop": 20}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rank</TableCell>
                                <TableCell>Team Name</TableCell>
                                <TableCell>Peg</TableCell>
                                <TableCell>Zone</TableCell>
                                <TableCell>Combined Weight</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                carpLeaderBoard && carpLeaderBoard.map((entry) => {
                                    console.log(entry)
                                    rank ++;
                                    return (
                                        <TableRow key={entry.id}>
                                            <TableCell>{rank}</TableCell>
                                            <TableCell>{entry.teamName}</TableCell>
                                            <TableCell>{entry.pegName}</TableCell>
                                            <TableCell>{entry.zone}</TableCell>
                                            <TableCell>{`${parseFloat(entry.combinedWeight).toFixed(2)} kg`}</TableCell>
                                            <TableCell><Button onClick={(data) => this.openDialog(entry)} color="primary">View</Button></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog maxWidth="sm" fullWidth open={this.state.dialogOpen} onClose={this.closeDialog}>
                    <DialogTitle>{this.state.dialogData.teamName ? this.state.dialogData.teamName : <LinearProgress />}</DialogTitle>
                    <DialogContent>
                        {
                            this.state.dialogOpen &&
                            this.state.dialogData.catches.map((thisCatch, index) => {
                                return (
                                    <Card key={index} style={{"marginBottom": 10}}>
                                        <CardHeader
                                            avatar={<Avatar src={thisCatch.photoUrl}/>}
                                            title={`${thisCatch.species} -- ${thisCatch.weight} kg`}
                                            subheader={`Caught by ${thisCatch.caughtBy} at ${moment(thisCatch.timestamp.toDate()).format("MMM Do, h:mm a")}`}
                                            action={
                                                <Button onClick={(url) => this.openPhotoDialog(thisCatch.photoUrl)} variant="contained" color="primary">View Photo</Button>
                                            }
                                        />
                                    </Card>
                                )
                            })
                        }
                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.closePhotoDialog} open={this.state.photoDialogOpen} maxWidth="sm" fullWidth>
                    
                    <DialogContent>
                        <img src={this.state.photoDialogUrl} width="100%" />
                    </DialogContent>
                </Dialog>
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        carpLeaderBoard: state.firestore.ordered.carpLeaderBoard
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {
            collection: 'carpLeaderBoard',
            orderBy: ['combinedWeight', 'desc']
        }
    ])
)(TeamLeaderboard)

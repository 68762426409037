import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

// Material UI Components
import { Typography, Card, CardContent, Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, CircularProgress } from '@material-ui/core';

// Material UI Icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const Attendees = (props) => {
    const { attendees, competition, id } = props
    return (
        <div className="fa-comp-card">
            <Container>
                <Card>
                    <CardContent>
                        <Typography variant="h4">Attendees</Typography>
                        {competition ? <Typography varaint="h5">{competition.title}</Typography> : <CircularProgress/>}
                        <div className="fa-comp-card">
                            <Link to={`/register-attendee/${id}`}><Button color="primary" variant="contained" startIcon={<PersonAddIcon/>}>Register Angler</Button></Link>
                        </div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Angler Name</TableCell>
                                        <TableCell>Angler Number</TableCell>
                                        <TableCell>Angler Contact Number</TableCell>
                                        { competition && competition.format === 'Teams' ? <TableCell>Team</TableCell> : null }
                                        <TableCell>Action </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { attendees && attendees.map((angler) => {
                                        return (
                                            <TableRow key={angler.id}>
                                                <TableCell>{`${angler.firstName} ${angler.lastName}`}</TableCell>
                                                <TableCell>TBA</TableCell>
                                                <TableCell>TBA</TableCell>
                                                { competition && competition.format === 'Teams' ? <TableCell>{angler.team.label}</TableCell> : null }
                                                <TableCell><Button color="primary">View</Button></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id
    return {
        id,
        attendees: state.firestore.ordered.attendees,
        competition: state.firestore.data.competition
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { 
            collection: 'competitions', 
            doc: props.id,
            storeAs: 'attendees',
            subcollections: [
                { collection: 'leaderboard' }
            ]
        },
        {
            collection: 'competitions',
            doc: props.id,
            storeAs: 'competition'
        }
    ])
)(Attendees)

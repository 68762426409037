import { Avatar, Button, Container, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'

class AllFishRanked extends Component {
    state = {
        dialogOpen: false,
        dialogUrl: ''
    }
    openDialog = (url) => {
        this.setState({
            dialogOpen: true,
            dialogUrl: url
        })
    }
    closeDialog = () => {
        this.setState({
            dialogOpen: false,
            dialogUrl: ''
        })
    }
    render() {
        const { carpCombined } = this.props
        console.log(carpCombined)
        let rank = 0;
        return (
            <div>
                <Container style={{"marginTop": 20}}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>Rank</TableCell>
                                    <TableCell>Species</TableCell>
                                    <TableCell>Weight</TableCell>
                                    <TableCell>Team</TableCell>
                                    <TableCell>Peg</TableCell>
                                    <TableCell>Zone</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    carpCombined && carpCombined.map((entry) => {
                                       
                                        rank ++;
                                        return (
                                            <TableRow key={entry.id}>
                                                <TableCell><Avatar src={entry.photoUrl} /></TableCell>
                                                <TableCell>{rank}</TableCell>
                                                <TableCell>{entry.species}</TableCell>
                                                <TableCell>{`${entry.weight} kg`}</TableCell>
                                                <TableCell>{entry.teamName}</TableCell>
                                                <TableCell>{entry.pegName}</TableCell>
                                                <TableCell>{entry.zone.label}</TableCell>
                                                <TableCell>{moment(entry.timestamp.toDate()).format("MMM Do, h:mm a")}</TableCell>
                                                <TableCell><Button onClick={(url) => this.openDialog(entry.photoUrl)} color="primary" variant="contained">View</Button></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog maxWidth="sm" fullWidth onClose={this.closeDialog} open={this.state.dialogOpen}>
                        <DialogContent>
                            <img src={this.state.dialogUrl} width="100%" />
                        </DialogContent>
                    </Dialog>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        carpCombined: state.firestore.ordered.carpCombined
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'carpCombined', orderBy: ['weight', 'desc'] }
    ])
)(AllFishRanked)

import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

// Material UI Components
import { Container, Card, CardContent, Typography, Grid, CardActions, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, TableFooter } from '@material-ui/core'

const Leaderboards = (props) => {
    const { compId, leaderboard, teamLeaderboard } = props
    console.log(leaderboard)
    let indRank = 0;
    let teamRank = 0;
    return (
        <div className="fa-comp-card">
            <Container>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Leaderboards</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography>Individual - Top 5</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>&nbsp;</TableCell>
                                                <TableCell>Angler Name</TableCell>
                                                <TableCell>Score</TableCell>
                                                <TableCell>Rank</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                leaderboard && leaderboard.map((angler) => {
                                                    indRank ++
                                                    if (angler.score > 0) {
                                                        return (
                                                            <TableRow key={angler.id}>
                                                                <TableCell>IMG</TableCell>
                                                                <TableCell>{`${angler.firstName} ${angler.lastName}`}</TableCell>
                                                                <TableCell>{angler.score}</TableCell>
                                                                <TableCell>{indRank}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    
                                                })
                                            }
                                            
                                        </TableBody>
                                        
                                    </Table>
                                    <Button color="primary">View Full Board</Button>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Teams - Top 5</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>&nbsp;</TableCell>
                                                <TableCell>Angler Name</TableCell>
                                                <TableCell>Score</TableCell>
                                                <TableCell>Rank</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                teamLeaderboard && teamLeaderboard.map((team) => {
                                                    teamRank ++
                                                    if (team.score > 0) {
                                                        return (
                                                            <TableRow key={team.id}>
                                                                <TableCell>IMG</TableCell>
                                                                <TableCell>{team.teamName}</TableCell>
                                                                {/* <TableCell>{team.score}</TableCell> */}
                                                                <TableCell>{teamRank}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    
                                                })
                                            }
                                            
                                        </TableBody>
                                        
                                    </Table>
                                    <Button color="primary">View Full Board</Button>
                                </TableContainer>
                                
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Zone</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Other Info Here</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        leaderboard: state.firestore.ordered.leaderboard,
        teamLeaderboard: state.firestore.ordered.teamLeaderboard,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { 
            collection: 'competitions',
            storeAs: 'leaderboard',
            doc: props.compId,
            subcollections: [
                { 
                    collection: 'leaderboard',
                    orderBy: ['score', 'desc'],
                    limit: 5
                }
            ]
        },
        {
            collection: 'competitions',
            storeAs: 'teamLeaderboard',
            doc: props.compId,
            subcollections: [
                { 
                    collection: 'teamLeaderboard',
                    orderBy: ['score', 'desc'],
                    limit: 5
                }
            ]
        }
    ])
)(Leaderboards)

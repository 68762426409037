import React, { Component } from 'react';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { getAnglerByIdNumber } from '../../../store/actions/competitionActions';
import { registerNewAngler } from '../../../store/actions/competitionActions';
import Select from 'react-select';

// Material UI Components
import { Typography, TextField, FormControl, Button, Card, CardContent, Container, CircularProgress, CardActions } from '@material-ui/core'

// Material UI Icons
import FindInPageIcon from '@material-ui/icons/FindInPage';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export class RegisterAttendee extends Component {
    state = {
        isExisting: false,
        idNumber: '',
        firstName: '',
        lastName: '',
        cellNumber: '',
        anglerId: '',
        anglerCompetitionCode: '',
        isChecking: false,
        completedCheck: false,
        email: '',
        team: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    restart = () => {
        this.setState({
            isExisting: false,
            idNumber: '',
            firstName: '',
            lastName: '',
            cellNumber: '',
            anglerId: '',
            anglerCompetitionCode: '',
            isChecking: false,
            completedCheck: false,
            email: '',
            team: ''
        })
    }
    
    runIdCheck = async () => {
        this.setState({
            ...this.state,
            isChecking: true,
        })
        let angler = await this.props.getAnglerByIdNumber(this.state.idNumber)
        console.log(angler)
        if (angler.idNumber) {
            this.setState({
                ...this.state,
                isExisting:true,
                isChecking: false,
                completedCheck: true,
                ...angler,
                anglerId: angler.uid
            })
        }
        else {
            this.setState({
                ...this.state,
                isExisting:false,
                isChecking: false,
                completedCheck: true
            })
        }
    }
    handleNewRegistration = async () => {
        await this.props.registerNewAngler(this.state, this.props.compId);
        this.restart();
    }
    handleSelectChange = (selectedOption, field) => {
        this.setState({
            [field]: selectedOption
        })
    }
    render() {
        const { teams } = this.props;
        console.log(teams)
        let teamArray = [];
        teams && teams.map((team) => {
            const teamObject = {
                value: team.id,
                label: team.teamName
            }
            teamArray.push(teamObject)
            console.log(teamArray)
        })
        return (
            <div>
                <Container>
                    <Card>
                        <CardContent>
                            {
                                this.state.isChecking
                                ?
                                <CircularProgress />
                                :
                                <div>
                                    {
                                        this.state.completedCheck
                                        ?
                                        <div className="fa-comp-card">
                                            <Button variant="contained" startIcon={<FindInPageIcon />} color="primary" onClick={this.restart}>Check Another ID</Button>
                                        </div>
                                        :
                                        <div className="fa-comp-card">
                                            <Typography>Check if Angler Exists in System:</Typography>
                                            <div className="fa-input-field">
                                                <FormControl fullWidth>
                                                    <TextField variant="outlined" id="idNumber" label="ID Number (use passport number if foreign national" onChange={this.handleChange}/>
                                                </FormControl>
                                            </div>
                                            <Button variant="contained" startIcon={<FindInPageIcon />} color="primary" onClick={this.runIdCheck}>Check</Button>
                                        </div>
                                    }
                                    
                                    {
                                        this.state.completedCheck
                                        ?
                                        <div>
                                            { this.state.isExisting
                                                ?
                                                <div>
                                                    <Card>
                                                        <CardContent>
                                                            <Typography>Angler Found: {`${this.state.firstName} ${this.state.lastName}`}</Typography>
                                                            <Typography>ID Number: {this.state.idNumber}</Typography>
                                                            <Typography>Cell Number: {this.state.cellNumber}</Typography>
                                                            <div className="fa-input-field">
                                                                <FormControl fullWidth>
                                                                    <TextField variant="outlined" id="anglerCompetitionCode" onChange={this.handleChange} label="Angler Code for Competition" />
                                                                </FormControl>
                                                            </div>
                                                            <div className="fa-input-field">
                                                                <Select
                                                                    name="team"
                                                                    options={teamArray}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Team"
                                                                    onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'teamId')}
                                                                />
                                                            </div>
                                                        </CardContent>
                                                        <CardActions>
                                                            {
                                                            this.state.anglerCompetitionCode !== ''
                                                            ?
                                                                <Button color="primary" startIcon={<PersonAddIcon/>} variant="contained">Add To Competition</Button>
                                                            :
                                                                <Button color="primary" disabled startIcon={<PersonAddIcon/>} variant="contained">Add To Competition</Button>
                                                            }
                                                            
                                                        </CardActions>
                                                    </Card>
                                                    
                                                </div>
                                                :
                                                <div>
                                                    <Card>
                                                        <CardContent>
                                                            <Typography>No Angler found with that ID Number, please create angler:</Typography>
                                                            <div className="fa-input-field">
                                                                <FormControl fullWidth>
                                                                    <TextField defaultValue={this.state.idNumber} label="ID Number" id="idNumber" onChange={this.handleChange} variant="outlined"/>
                                                                </FormControl>
                                                            </div>
                                                            {
                                                                teams.length > 0
                                                                ?
                                                                <div className="fa-input-field">
                                                                    <Select
                                                                        name="team"
                                                                        options={teamArray}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder="Team"
                                                                        onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'team')}
                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div className="fa-input-field">
                                                                <FormControl fullWidth>
                                                                    <TextField label="First Name" id="firstName" onChange={this.handleChange} variant="outlined"/>
                                                                </FormControl>
                                                            </div>
                                                            <div className="fa-input-field">
                                                                <FormControl fullWidth>
                                                                    <TextField label="Last Name" id="lastName" onChange={this.handleChange} variant="outlined"/>
                                                                </FormControl>
                                                            </div>
                                                            <div className="fa-input-field">
                                                                <FormControl fullWidth>
                                                                    <TextField label="Cell Number" id="cellNumber" onChange={this.handleChange} variant="outlined"/>
                                                                </FormControl>
                                                            </div>
                                                            <div className="fa-input-field">
                                                                <FormControl fullWidth>
                                                                    <TextField label="E-Mail" id="email" onChange={this.handleChange} variant="outlined"/>
                                                                </FormControl>
                                                            </div>
                                                            <div className="fa-input-field">
                                                                <FormControl fullWidth>
                                                                    <TextField variant="outlined" id="anglerCompetitionCode" onChange={this.handleChange} label="Angler Code for Competition" />
                                                                </FormControl>
                                                            </div>
                                                            
                                                            
                                                        </CardContent>
                                                        <CardActions>
                                                            {
                                                            this.state.anglerCompetitionCode !== '' && this.state.firstName !== '' && this.state.lastName !== '' && this.state.cellNumber !== '' && this.state.email !== ''
                                                            ?
                                                                <Button onClick={this.handleNewRegistration} color="primary" startIcon={<PersonAddIcon/>} variant="contained">Register & Add To Competition</Button>
                                                            :
                                                                <Button color="primary" disabled startIcon={<PersonAddIcon/>} variant="contained">Register & Add To Competition</Button>
                                                            }
                                                        </CardActions>
                                                    </Card>
                                                </div>
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            }
                            
                        </CardContent>
                    </Card>
                </Container>
                
                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    
    return {
        compId: ownProps.match.params.id,
        teams: state.firestore.ordered.teams
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAnglerByIdNumber: (idNumber) => dispatch(getAnglerByIdNumber(idNumber)),
        registerNewAngler: (angler, compId) => dispatch(registerNewAngler(angler, compId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect( props => [
        { 
            collection: 'competitions',
            doc: props.compId,
            storeAs: 'teams',
            subcollections: [
                { collection: 'teams' }
            ],
            
        }
    ])
)(RegisterAttendee)

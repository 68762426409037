import React from 'react';
import { connect } from 'react-redux';
import SignIn from './components/auth/SignIn';
import AppAuthed from './AppAuthed';
import Public from './components/competitions/carpclassic/public/Public';

function App(props) {
  const { auth } = props;
  return (
    auth.uid ? <AppAuthed /> : <Public />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(App);

import { Avatar, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, LinearProgress, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import EditIcon from '@material-ui/icons/Edit';
import { editCarpCatch, deleteCarpCatch } from '../../../../../store/actions/competitionActions'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class EditCarpCatch extends Component {
    state = {
        dialogOpen: false,
        targetName: '',
        targetValue: '',
        deleteOpen: false
    }
    openDialog = (targetName, targetValue) => {
        this.setState({
            dialogOpen: true,
            targetName,
            targetValue
        })
    }
    closeDialog = () => {
        this.setState({
            dialogOpen: false,
            targetName: '',
            targetValue: ''
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleConfirm = async (docId, target, value) => {
        await this.props.editCarpCatch(docId, target, value );
        this.closeDialog();
    }
    openDeleteDialog = () => {
        this.setState({
            deleteOpen: true
        })
    }
    closeDeleteDialog = () => {
        this.setState({
            deleteOpen: false
        })
    }
    confirmDelete = async () => {
        await this.props.deleteCarpCatch(this.props.catchId);
        this.props.history.push('/carpclassic/catches');
    }
    render() {
        const { catchId, thisCatch } = this.props;
        console.log(thisCatch)
        if(thisCatch) {
            return (
                <div>
                    <Container style={{"marginTop": 20}}>
                        <Link to='/carpclassic/catches'><IconButton children={<ArrowBackIcon />}   /></Link>
                        <Card>
                            <CardHeader 
                                avatar={
                                    <Avatar src={thisCatch.photoUrl} />
                                }
                                title={thisCatch.species}
                                subheader={thisCatch.teamName}
                            />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardHeader 
                                                title={`${thisCatch.weight} kg`}
                                                subheader={'Weight'}
                                                action={<IconButton onClick={(name, value) => this.openDialog
                                                ('weight', thisCatch.weight)
                                                } children={<EditIcon />} />}
                                            />
                                        </Card>
                                        <br/>
                                        <Card>
                                            <CardHeader 
                                                title={thisCatch.teamName}
                                                subheader={'TeamName'}
                                                action={<IconButton onClick={(name, value) => this.openDialog
                                                    ('teamName', thisCatch.teamName)
                                                    } children={<EditIcon />} />}
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardHeader 
                                                title={thisCatch.species}
                                                subheader={'Species'}
                                                action={<IconButton onClick={(name, value) => this.openDialog
                                                    ('species', thisCatch.species)
                                                    } children={<EditIcon />} />}
                                            />
                                        </Card>
                                        <br/>
                                        <Card>
                                            <CardHeader 
                                                title={thisCatch.caughtBy}
                                                subheader={'CaughtBy'}
                                                action={<IconButton onClick={(name, value) => this.openDialog
                                                    ('caughtBy', thisCatch.caughtBy)
                                                    } children={<EditIcon />} />}
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Button onClick={this.openDeleteDialog} variant="contained" color="secondary" startIcon={<DeleteForeverIcon />}>Delete</Button>
                            </CardContent>
                        </Card>
                        <Dialog open={this.state.dialogOpen} onClose={this.closeDialog}>
                            <DialogContent>
                                <TextField id="targetValue" onChange={this.handleChange} label={`${this.state.targetName}`} value={this.state.targetValue} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeDialog} color="secondary">Cancel</Button>
                                <Button onClick={(docId, target, value) => this.handleConfirm(
                                    catchId,
                                    this.state.targetName,
                                    this.state.targetValue
                                )} color="primary">Confirm</Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.deleteOpen} onClose={this.closeDeleteDialog}>
                            <DialogContent>
                                Are you sure you want to delete catch {this.props.catchId}. This action can not be undone.
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeDeleteDialog}>Cancel</Button>
                                <Button startIcon={<DeleteForeverIcon />} variant="contained" color="secondary" onClick={this.confirmDelete}>Delete</Button>
                            </DialogActions>

                        </Dialog>
                    </Container>
                </div>
            )
        }
        else {
            return (
                <Container style={{"marginTop": 20}}>
                    <LinearProgress />
                </Container>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        catchId: ownProps.match.params.id,
        thisCatch: state.firestore.data.thisCatch
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editCarpCatch: (docId, target, value) => dispatch(editCarpCatch(docId, target, value)),
        deleteCarpCatch: (docId) => dispatch(deleteCarpCatch(docId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        {
            collection: 'carpCombined',
            doc: props.catchId,
            storeAs: 'thisCatch'
        }
    ])
)(EditCarpCatch)

const initState = {

}

const competitionReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_COMP':
            console.log('Competition Added!')
            return state;
        case 'ADD_COMP_ERROR':
            console.log('Error adding Competition')
            return state;
        case 'NEW_REGISTRATION':
            console.log('User Registration Request Submitted')
            return state;
        case 'NEW_REGISTRATION_ERROR':
            console.log('Error Requesting new Registration')
            return state;
        case 'NEW_TEAM_ADD':
            console.log('Added New Team')
            return state;
        case 'NEW_TEAM_ADD_ERROR':
            console.log('Error adding New Team')
            return state;
        case 'ADDED_ZONE':
            console.log('Added New Zone')
            return state;
        case 'ADDED_ZONE_ERROR':
            console.log('Error Adding New Zone')
            return state;
        case 'ADDED_PEG':
            console.log('Added New Peg')
            return state;
        case 'ADDED_PEG_ERROR':
            console.log('Error Adding New Peg')
            return state;
        case 'ASSIGNED_PEG':
            console.log('Assigned Peg')
            return state;
        case 'ASSIGNED_PEG_ERROR':
            console.log('Error Assigning Peg')
            return state;
        case 'ASSIGNED_TEAM_PEG':
            console.log('Team marked as assigned')
            return state;
        case 'ASSIGNED_TEAM_PEG_ERROR':
            console.log('Error Marking Team as assigned')
            return state;
        case 'MARSHALL_ADDED':
            console.log('Added to Marshall Collection waiting for claim update')
            return state;
        case 'MARSHALL_ADDED_ERROR':
            console.log('Error adding to Marshall collection')
            return state;
        case 'CARP_MARSHALL_SUCCESS':
            console.log('Added Carp Marshall')
            return state;
        case 'CARP_MARSHALL_ERROR':
            console.log('Error adding Carp Marshall', action.error)
            return state;
        case 'EDIT_CARP':
            console.log('Edited Carp Catch')
            return state;
        case 'EDIT_CARP_ERROR':
            console.log('Error editing Carp Catch', action.error)
            return state;
        case 'CATCH_DELETE':
            console.log('Deleted Carp Catch')
            return state;
        case 'CATCH_DELETE_ERROR':
            console.log('Error deleting Carp Catch', action.error)
        case 'DELETE_TEAM':
            console.log('Deleted Carp Team')
            return state;
        case 'DELETE_TEAM_ERROR':
            console.log('Error deleting Carp Team', action.error)
            return state;
        case 'DELETE_ZONE':
            console.log('Deleted Carp Team')
            return state;
        case 'DELETE_ZONE_ERROR':
            console.log('Error deleting Carp Team', action.error)
            return state;
        case 'DELETE_PEG':
            console.log('Deleted Carp Team')
            return state;
        case 'DELETE_PEG_ERROR':
            console.log('Error deleting Carp Team', action.error)
            return state;
        default:
            return state
    }
}

export default competitionReducer
import React, { Component } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from 'react-select';

import { connect } from 'react-redux';
import { createCompetition } from '../../store/actions/competitionActions';
import FileUploader from 'react-firebase-file-uploader'
import firebase from 'firebase/app'
import 'firebase/storage'
import CircularProgress from '@material-ui/core/CircularProgress'

// Material UI Components
import { FormControl, TextField, FormControlLabel, Switch } from '@material-ui/core';



export class NewComp extends Component {
    state = {
        title: '',
        compStartDate: new Date(),
        compEndDate: new Date(),
        startTime: new Date(),
        venue: '',
        scoringSystem: '',
        maxEntries: 0,
        entryPrice: 0,
        logoImg: '',
        backgroundImg: '',
        format: '',
        logoImgSpinner: '',
        backgroundImgSpinner: '',
        useZones: false,
        usePegs: false
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault()
        await this.props.createCompetition(this.state);
        this.props.history.push('/')
    }
    handleStartDateChange = date => {
        this.setState({
            compStartDate: date,
            compEndDate: date
        })
    }
    handleEndDateChange = date => {
        this.setState({
            compEndDate: date
        })
    }
    handleTimeChange = date => {
        this.setState({
            compStartTime: date
        })
    }
    handleSelectChange = (selectedOption, field) => {
        this.setState({
            [field]: selectedOption.value
        })
    }
    handleMultiSelectChange = async (selectedOption, field) => {
       
        var itemArray = [];
        await selectedOption.map((item) => {
            itemArray.push(item.value)
        });

        this.setState({
          ...this.state,
          [field]: itemArray
        });

    }

    handleUploadStart = (target) => {
        this.setState({
            isUploading:true,
            progress: 0,
            [target+'Spinner']:<CircularProgress/>
        })
    }

    handleProgress = progress => this.setState({progress})

    handleUploadError = error => {
        this.setState({
            isUploading: false,
            spinner:''
        })
        console.log('Upload Error', error)
    }
    
    handleUploadSuccess = (filename, target, type) => {
        this.setState({
            progress: 100,
            isUploading: false,
            [target+'Spinner']:''
        })
        firebase.storage().ref(type).child(filename)
        .getDownloadURL()
        .then(url => this.setState({
            [target]: url,
            fileName: filename
        }))

    }

    handleSwitchChange = async (e, name) => {
        if (e.target.value === 'false') {
            await this.setState({
                ...this.state,
                [name]: true
            })
        } else {
            await this.setState({
                ...this.state,
                [name]: false
            })
        }
    }


    render() {
        
        const species = [
            {value:'All', label: 'All'},
            {value:'Bass - Largemouth', label: 'Bass - Largemouth'},
            {value:'Bass- Smallmouth', label: 'Bass- Smallmouth'},
            {value:'Carp', label: 'Carp'},
            {value:'Catfish - Rock', label: 'Catfish - Rock'},
            {value:'Catfish - Sharptooth (Barbel)', label: 'Catfish - Sharptooth (Barbel)'},
            {value:'Catfish - Silver (Makriel0', label: 'Catfish - Silver (Makriel0'},
            {value:'Eel', label: 'Eel'},
            {value:'Goldfish', label: 'Goldfish'},
            {value:'Grass Carp', label: 'Grass Carp'},
            {value:'Kurper - Blue', label: 'Kurper - Blue'},
            {value:'Kurper - Canary', label: 'Kurper - Canary'},
            {value:'Kurper - Redbreast', label: 'Kurper - Redbreast'},
            {value:'Kurper - Vlei', label: 'Kurper - Vlei'},
            {value:'Lead fish', label: 'Lead fish'},
            {value:'Mackerel', label: 'Mackerel'},
            {value:'Moggel', label: 'Moggel'},
            {value:'Mudfish - Orange River', label: 'Mudfish - Orange River'},
            {value:'Mudfish - Rednose', label: 'Mudfish - Rednose'},
            {value:'Papermouth', label: 'Papermouth'},
            {value:'Perch', label: 'Perch'},
            {value:'Silver Carp', label: 'Silver Carp'},
            {value:'Tench', label: 'Tench'},
            {value:'Tigerfish', label: 'Tigerfish'},
            {value:'Trout - Brown', label: 'Trout - Brown'},
            {value:'Trout - Rainbow', label: 'Trout - Rainbow'},
            {value:'White Fish', label: 'White Fish'},
            {value:'Yellow Fish - Large Scale', label: 'Yellow Fish - Large Scale'},
            {value:'Yellow Fish - Natal', label: 'Yellow Fish - Natal'},
            {value:'Yellow Fish - Small Mouth', label: 'Yellow Fish - Small Mouth'},
            {value:'Yellow Fish - Small Scale', label: 'Yellow Fish - Small Scale'},
        ]
        const scoringSystem = [
            {value:'Weight Based - Biggest Fish', label: 'Weight Based - Biggest Fish'},
            {value:'Weight Based - Biggest 3', label: 'Weight Based - Biggest 3'},
            {value:'Weight Based - Biggest Total', label: 'Weight Based - Biggest Total'},
            {value:'Weight Based - Points System A', label: 'Points System A'},
            {value:'Weight Based - Points System B', label: 'Points System B'},
            {value:'Weight Based - Points System C', label: 'Points System C'},
        ]
        const teamOrInd = [
            {value:'Teams', label: 'Teams'},
            {value:'Individuals', label: 'Individuals'},
        ]

        return (
            <div className="container">
                <div className="card">
                    <div className="card-content">
                        <span className="card-title">
                            New Competition
                        </span>
                        <form onSubmit={this.handleSubmit}>
                            <div className="input-field">
                                <label htmlFor="title">Competition Name</label>
                                <input type="text" required id="title" onChange={this.handleChange}/>
                            </div>
                            {/* Start Date Picker Stuff */}
                            <div className="input-field row">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="col s12 m4 l4">
                                        <DatePicker
                                            disableToolbar
                                            disablePast
                                            autoOk
                                            variant="dialog"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Competition Start Date"
                                            value={this.state.compStartDate}
                                            onChange={this.handleStartDateChange}
                                           
                                        />
                                    </div>
                                    <div className="col s12 m4 l4">
                                        <DatePicker
                                            disableToolbar
                                            disablePast
                                            variant="dialog"
                                            autoOk
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Competition End Date"
                                            value={this.state.compEndDate}
                                            onChange={this.handleEndDateChange}
                                            
                                        />
                                    </div>
                                    <div className="col s12 m4 l4">
                                    <TimePicker
                                        label="Event Start Time"
                                        margin="normal"
                                        placeholder="08:00 AM"
                                        mask="__:__ _M"
                                        value={this.state.compStartTime}
                                        onChange={date => this.handleTimeChange(date)}
                                    />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            {/* End Date Picker Stuff */}
                            <div className="input-field">
                                <label htmlFor="venue">Venue / Dam Name</label>
                                <input type="text" id="venue" required onChange={this.handleChange}/>
                            </div>
                            <div className="input-field">
                                <label htmlFor="entryLimit">Max. Number of Entries</label>
                                <input type="number" id="maxEntries" required onChange={this.handleChange}/>
                            </div>
                            <div className="input-field">
                                <Select
                                    isMulti
                                    name="species"
                                    options={species}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select Which Species Are Allowed"
                                    onChange={(selectedOption) => this.handleMultiSelectChange(selectedOption, 'species')}
                                />
                            </div>
                            <div className="input-field">
                                <Select
                                    name="scoringSystem"
                                    options={scoringSystem}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Scoring System"
                                    onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'scoringSystem')}
                                />
                                <i className="grey-text">Learn more about points systems <b>here</b></i>
                            </div>
                            <div className="input-field">
                                <Select
                                    name="format"
                                    options={teamOrInd}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Teams or Individual Anglers"
                                    onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'format')}
                                />
                            </div>
                            <div className="fa-input-field">
                                <label htmlFor="entryPrice">Entry Price - ZAR</label>
                                <input type="number" id="entryPrice" required onChange={this.handleChange}/>
                            </div>

                            <div className="fa-input-field">
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={this.state.useZones}
                                        onChange={(e) => this.handleSwitchChange(e, 'useZones')}
                                        value={this.state.useZones}
                                        color="primary"
                                    />
                                    }
                                    label="Use Zones?"
                                />
                            </div>
                            <div className="fa-input-field">
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={this.state.usePegs}
                                        onChange={(e) => this.handleSwitchChange(e, 'usePegs')}
                                        value={this.state.usePegs}
                                        color="primary"
                                    />
                                    }
                                    label="Use Pegs?"
                                />
                            </div>
                            

                            <div className="fa-input-field">
                                <p>Logo Image:</p>
                                {this.state.logoImgSpinner}<br/>
                                <FileUploader
                                    accept="image/*"
                                    name="logoImg"
                                    storageRef={firebase.storage().ref('logoImg')}
                                    onUploadStart={() => this.handleUploadStart('logoImg')}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={(filename) => this.handleUploadSuccess(filename, 'logoImg', 'logoImg')}
                                    onProgress={this.handleProgress}
                                />
                            </div>
                            <div className="fa-input-field">
                                <p>Background Image:</p>
                                {this.state.backgroundImgSpinner}<br/>
                                <FileUploader
                                    accept="image/*"
                                    name="backgroundImg"
                                    storageRef={firebase.storage().ref('backgroundImg')}
                                    onUploadStart={() => this.handleUploadStart('backgroundImg')}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={(filename) => this.handleUploadSuccess(filename, 'backgroundImg', 'backgroundImg')}
                                    onProgress={this.handleProgress}
                                />
                            </div>

                            {/* TODO: Add Payment Method Checkboxes */}
                            <div className="input-field">
                                <button className="btn">Create Competition</button>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createCompetition: (competition) => dispatch(createCompetition(competition))
    }
}

export default connect(null, mapDispatchToProps)(NewComp)

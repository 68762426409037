export const createCompetition = (competition) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const firestore = getFirestore();
        
        firestore.collection('competitions').add({
            ...competition
        }).then((doc) => {
            dispatch({ type: 'ADD_COMP', doc})
        }).catch((err) => {
            dispatch({ type: 'ADD_COMP_ERROR', err})
        })

    }
}

export const getAnglerByIdNumber = (idNumber) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        let angler = {};

        await firestore.collection('users').where('idNumber','==', idNumber)
            .get()
            .then((snap) => {
                snap.forEach((doc) => {
                    angler = doc.data()
                })
            })
        
        return angler;
    }
}

export const registerNewAngler = (angler, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        console.log(angler)
        let registrationCall = firebase.functions().httpsCallable('compUserRegistration');
        registrationCall(angler).then(async (result) => {
            console.log(result)
            await firestore.collection('competitions').doc(compId).collection('leaderboard').doc(result.data.anglerId).set({
                ...result.data.newUser,
                score: 0
            }).then(() => {
                dispatch({ type: 'NEW_REGISTRATION' });
            }).catch((err) => {
                dispatch({ type: 'NEW_REGISTRATION_ERROR' })
            })
            return result;
        });

    }
}

export const addTeamToComp = (team, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firestore.collection('competitions').doc(compId).collection('teams').add({
            ...team
        }).then(() => {
            dispatch({ type: 'NEW_TEAM_ADD'})
        }).catch((err) => {
            dispatch({ type: 'NEW_TEAM_ADD_ERROR'})
        })

    }
}

export const addZoneToComp = (zone, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('competitions').doc(compId).collection('zones').add({
            ...zone
        }).then(() => {
            dispatch({ type: 'ADDED_ZONE' })
        }).catch((err) => {
            dispatch({ type: 'ADDED_ZONE_ERROR'})
        })
    }
}

export const addPegToComp = (peg, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('competitions').doc(compId).collection('pegs').add({
            ...peg
        }).then(() => {
            dispatch({ type: 'ADDED_PEG' })
        }).catch((err) => {
            dispatch({ type: 'ADDED_PEG_ERROR'})
        })
    }
}

export const assignTeamToPeg = (compId, pegId, pegNumber, team) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        await firestore.collection('competitions').doc(compId).collection('pegs').doc(pegId)
            .update({
                assigned: true,
                teamName: team.label,
                teamId: team.value
            }).then(() => {
                dispatch({ type: 'ASSIGNED_PEG' })
            }).catch((err) => {
                dispatch({ type: 'ASSIGNED_PEG_ERROR' })
            })

        firestore.collection('competitions').doc(compId).collection('teams').doc(team.value)
            .update({
                assignedPeg: true
            }).then(() => {
                dispatch({ type: 'ASSIGNED_TEAM_PEG' })
            }).catch((err) => {
                dispatch({ type: 'ASSIGNED_TEAM_PEG_ERROR' })
            })
    }
}

export const searchForAngler = (email) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        let angler = {};

        await firestore.collection('users').where('email','==', email)
            .get()
            .then((snap) => {
                snap.forEach((doc) => {
                    angler = doc.data()
                })
            })
        
        return angler;
    }
}

export const addMarshallToComp = (marshall, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('competitions').doc(compId).collection('marshalls').doc(marshall.uid)
            .set({
                ...marshall
            }).then(() => {
                dispatch({ type: 'MARSHALL_ADDED'})
            }).catch((err) => {
                dispatch({ type: 'MARSHALL_ADDED_ERROR', err})
            })
    }
}

export const addCarpMarshall = (creds) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('marshallTempReg').add({
            email: creds.email,
            pass: creds.password
        }).then(() => {
            dispatch({ type: 'CARP_MARSHALL_SUCCESS' })
        }).catch((error) => {
            dispatch({ type: 'CARP_MARSHALL_ERROR', error})
        })
    }
}

export const editCarpCatch = (docId, target, value) => {

    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        let conValue;
        if (target === 'weight') {
            conValue = parseFloat(value)
        }
        else {
            conValue = value
        }

        firestore.collection('carpCombined').doc(docId).update({
            [target]: conValue
        }).then((doc) => {
            dispatch({ type: 'EDIT_CARP'})
        }).catch((error) => {
            dispatch({ type: 'EDIT_CARP_ERROR', error})
        })
    }
}

export const deleteCarpCatch = (docId) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();

        firestore.collection('carpCombined').doc(docId)
        .delete()
        .then(() => {
            dispatch({ type: 'CATCH_DELETE'})
        }).catch((error) => {
            dispatch({ type: 'CATCH_DELETE_ERROR'})
        })


    }
}

export const deleteCarpTeam = (docId, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('competitions').doc(compId).collection('teams').doc(docId)
        .delete()
        .then(() => {
            dispatch({ type: 'DELETE_TEAM' })
        }).catch((error) => {
            dispatch({ type: 'DELETE_TEAM_ERROR' })
        })

    }
}

export const deleteZone = (docId, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('competitions').doc(compId).collection('zones').doc(docId)
        .delete()
        .then(() => {
            dispatch({ type: 'DELETE_ZONE' })
        }).catch((error) => {
            dispatch({ type: 'DELETE_ZONE_ERROR' })
        })
    }
}

export const deletePeg = (docId, compId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('competitions').doc(compId).collection('pegs').doc(docId)
        .delete()
        .then(() => {
            dispatch({ type: 'DELETE_PEG' })
        }).catch((error) => {
            dispatch({ type: 'DELETE_PEG_ERROR' })
        })
    }
}
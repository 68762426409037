import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAwQSKRMMJMVWXKFSbxbPikay52L7SihxQ",
    authDomain: "for-anglers-main.firebaseapp.com",
    databaseURL: "https://for-anglers-main.firebaseio.com",
    projectId: "for-anglers-main",
    storageBucket: "for-anglers-main.appspot.com",
    messagingSenderId: "1000884150627",
    appId: "1:1000884150627:web:fdaeb539e08b66604a7356",
    measurementId: "G-N2JXY9D96F"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;
import React from 'react'
import { Link } from 'react-router-dom'
import SignedInLinks from './SignedInLinks'
import logo from '../../img/falogobadge.png'

export const Navbar = () => {
    return (
        <nav className="nav-wrapper grey darken-4">
            <div className="container">
                <Link to='/' className="brand-logo"><img width="40" src={logo}/></Link>
                <SignedInLinks/>
                
            </div>
        </nav>
    )
}

import React, { Component } from 'react'
import { CompetitionCard } from './CompetitionCard'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'



export class Dashboard extends Component {
    render() {
        const { competitions } = this.props
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m8 l8">
                        <div className="card">
                            <div className="card-content" style={{"overflow":"auto"}}>
                                <span className="card-title">Competitions</span>
                                { competitions && competitions.map((comp) => {
                                    return (
                                        <div className="fa-comp-card" key={comp.id}>
                                            <CompetitionCard 
                                                title={comp.title}
                                                venue={comp.venue}
                                                date={comp.compDate}
                                                id={comp.id}
                                                format={comp.format}
                                                usePegs={comp.usePegs}
                                                useZones={comp.useZones}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4 l4">
                        <div className="card">
                            <div className="card-content">
                                <span className="card-title">Notifications</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        competitions: state.firestore.ordered.competitions
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'competitions' }
    ])
)(Dashboard)

import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'

// Material UI Components
import { Container, Card, CardContent, Typography, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, CardActions, Button } from '@material-ui/core'

// Material UI Components
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const Marshalls = (props) => {
    const { compId, marshalls } = props
    console.log(marshalls)
    return (
        <div className="fa-comp-card">
            <Container>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Marshalls</Typography>
                        <Typography>Use button below to add Marshalls to the app</Typography>
                        {/* <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Marshall Email</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        marshalls && marshalls.map((marshall) => {
                                            return (
                                                <TableRow key={marshall.id}>
                                                    <TableCell>{`${marshall.email}`}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </CardContent>
                    <CardActions>
                        <Link to={`/carp-classic/add-marshall`}><Button startIcon={<PersonAddIcon/>} variant="contained" color="primary">Add Marshall</Button></Link>
                    </CardActions>
                </Card>
            </Container>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        marshalls: state.firestore.ordered.marshalls
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { 
            collection: 'carpMarshalls',
            storeAs: 'marshalls'
        }
    ])
)(Marshalls)
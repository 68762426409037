import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import { deletePeg } from '../../../store/actions/competitionActions'

// Material UI Components
import { Container, Card, CardContent, Typography, TableContainer, Paper, Table, TableBody, TableRow, TableCell, TableHead, CardActions, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'

// Material UI Icons
import MapIcon from '@material-ui/icons/Map';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


export class Pegs extends Component {
    state = {
        deleteOpen: false,
        selectedDoc: ''
    }
    openDeleteDialog = (docId) => {
        this.setState({
            deleteOpen: true,
            selectedDoc: docId
        })
    }
    closeDeleteDialog = () => {
        this.setState({
            deleteOpen: false,
            selectedDoc: ''
        })
    }
    confirmDelete = async () => {
        await this.props.deletePeg(this.state.selectedDoc, this.props.compId);
        this.closeDeleteDialog();
    }
    render() {
        const { compId, pegs } = this.props
    console.log(pegs)
    return (
        <div className="fa-comp-card">
            <Container>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Pegs</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Peg Name</TableCell>
                                        <TableCell>Zone</TableCell>
                                        <TableCell>Team</TableCell>
                                        <TableCell>Map</TableCell>
                                        <TableCell>Assign</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        pegs && pegs.map((peg) => {
                                            return (
                                                <TableRow key={peg.id}>
                                                    <TableCell>{peg.pegName}</TableCell>
                                                    <TableCell>{peg.zone.label}</TableCell>
                                                    { peg.teamName ? <TableCell>{peg.teamName}</TableCell> : <TableCell>Not Assigned</TableCell>}
                                                    <TableCell><a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${peg.coords}`}><Button startIcon={<MapIcon/>} color="primary" variant="contained">View On Map</Button></a></TableCell>
                                                    <TableCell><Link to={`/${compId}/assign-peg/${peg.id}/${peg.pegNumber}`}><Button startIcon={<GroupAddIcon/>} variant="contained" color="primary">Assign Peg</Button></Link></TableCell>
                                                    <TableCell><Button onClick={(docId) => this.openDeleteDialog(peg.id)} startIcon={<DeleteForeverIcon />} color="secondary">Delete</Button></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <CardActions>
                        <Link to={`/${compId}/add-peg`}><Button startIcon={<AddLocationIcon />} variant="contained" color="primary">Add Peg</Button></Link>
                    </CardActions>
                </Card>
                <Dialog open={this.state.deleteOpen} onClose={this.closeDeleteDialog}>
                    <DialogContent>
                        Are you sure you want to delete this peg? This action can not be undone.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteDialog}>Cancel</Button>
                        <Button startIcon={<DeleteForeverIcon />} variant="contained" color="secondary" onClick={this.confirmDelete}>Delete</Button>
                    </DialogActions>

                </Dialog>
            </Container>
        </div>
    )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        pegs: state.firestore.ordered.pegs 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deletePeg: (docId, compId) => dispatch(deletePeg(docId, compId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { 
            collection: 'competitions',
            storeAs: 'pegs',
            doc: props.compId,
            subcollections: [
                { collection: 'pegs', orderBy: ['pegName','desc'] }
            ]
        }
    ])
)(Pegs)

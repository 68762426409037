import React, { Component } from 'react'
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { searchForAngler, addMarshallToComp } from '../../../store/actions/competitionActions'

// Material UI Components
import { Container, Card, CardContent, Typography, FormControl, TextField, CardActions, Button, CircularProgress } from '@material-ui/core'

class AddMarshall extends Component {
    state = {
        email: '',
        isFound: false,
        isSearching: false,
        message: '',
        angler: {}
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    runSearch = async () => {
        this.setState({
            ...this.state,
            isSearching: true
        });
        let angler = await this.props.searchForAngler(this.state.email);
        console.log(angler)
        if (angler.email === this.state.email) {
            this.setState({
                ...this.state,
                isFound: true,
                isSearching: false,
                message: 'User Found!',
                angler
            })
        } else {
            this.setState({
                ...this.state,
                isFound: false,
                isSearching: false,
                message: 'User Not Found :('
            })
        }
    }
    resetState = () => {
        this.setState({
            email: '',
            isFound: false,
            isSearching: false,
            message: '',
            angler: {}
        })
    }
    addToComp = async () => {
       
       await this.props.addMarshallToComp(this.state.angler, this.props.compId);
       this.props.history.push(`/${this.props.compId}/marshalls/`);
    }
    render() {
        return (
            <div className="fa-comp-card">
                <Container>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Add Marshall To Competition</Typography>
                            <Typography>Note: Marshall must be existing app user.</Typography>
                            {
                                !this.state.isSearching 
                                ?
                                <div className="fa-input-field">
                                    <FormControl fullWidth>
                                        <TextField type="email" id="email" variant="outlined" label="Email Address" onChange={this.handleChange} />
                                    </FormControl>
                                </div>
                                :
                                <CircularProgress/>
                            }
                            <Typography>{this.state.message}</Typography>
                            {
                                this.state.isFound
                                ?
                                    <Card>
                                        <CardContent>
                                            <Typography>{this.state.angler.firstName} {this.state.angler.lastName}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={this.addToComp} variant="contained" color="primary">Add To Competition</Button>
                                        </CardActions>
                                    </Card>
                                :
                                    null
                            }
                        </CardContent>
                        <CardActions>
                            <Button onClick={this.runSearch} variant="contained" color="primary">Search</Button>
                        </CardActions>
                    </Card>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchForAngler: (email) => dispatch(searchForAngler(email)),
        addMarshallToComp: (marshall, compId) => dispatch(addMarshallToComp(marshall, compId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
)(AddMarshall)

import React, { Component } from 'react'
import { addTeamToComp } from '../../../store/actions/competitionActions'
import { connect } from 'react-redux'

// Material UI Components
import { Container, Card, CardContent, Typography, FormControl, TextField, Button } from '@material-ui/core'

class AddTeam extends Component {
    state = {
        teamName: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = async (e) => {
        await this.props.addTeamToComp(this.state, this.props.compId);
        this.props.history.push(`/${this.props.compId}/teams`)
    }
    render() {
        return (
            <div className="fa-comp-card">
                <Container>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Add Team To Competition</Typography>
                            <div className="fa-input-field">
                                <FormControl fullWidth>
                                    <TextField variant="outlined" label="Team Name" required id="teamName" onChange={this.handleChange} />
                                </FormControl>
                            </div>
                            <div className="fa-input-field">
                                <Button variant="contained" onClick={this.handleSubmit} color="primary">Save</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    return {
        compId: ownProps.match.params.id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addTeamToComp: (team, compId) => dispatch(addTeamToComp(team,compId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTeam)

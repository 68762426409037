import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { addZoneToComp } from '../../../store/actions/competitionActions'

// Material UI Components
import { Container, Card, CardContent, Typography, CircularProgress, FormControl, TextField, Button } from '@material-ui/core'

class AddZone extends Component {
    state = {
        zoneName: '',
        coords: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = async (e, zoneNumber) => {
        await this.props.addZoneToComp({...this.state, zoneNumber}, this.props.compId)
        this.props.history.push(`/${this.props.compId}/zones`)
    }
    render() {
        const { zones } = this.props
        return (
            <div className="fa-comp-card">
                <Container>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Add Zone Number: {zones ? zones.length+1 : <CircularProgress />}</Typography>
                            <div className="fa-input-field">
                                <FormControl fullWidth>
                                    <TextField variant="outlined" id="zoneName" label="Zone Name" onChange={this.handleChange} />
                                </FormControl>
                            </div>
                            <div className="fa-input-field">
                                <FormControl fullWidth>
                                    <TextField variant="outlined" id="coords" label="Optional - Paste GPS Coordinates" onChange={this.handleChange} />
                                </FormControl>
                            </div>
                            <div className="fa-input-field">
                                <Button onClick={(e) => this.handleSubmit(e, zones.length+1)} variant="contained" color="primary">Save</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        compId: ownProps.match.params.id,
        zones: state.firestore.ordered.zones
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addZoneToComp: (zone, compId) => dispatch(addZoneToComp(zone, compId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { 
            collection: 'competitions',
            doc: props.compId,
            storeAs: 'zones',
            subcollections: [
                { collection: 'zones' }
            ]
        }
    ])
)(AddZone)

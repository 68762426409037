import { Button, Card, CardContent, Container, TextField, Typography, CardActions } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { addCarpMarshall } from '../../../../store/actions/competitionActions'
import { Link } from 'react-router-dom'

class AddCarpMarshall extends Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = async () => {
        await this.props.addCarpMarshall(this.state);
        this.props.history.push('/')
    }
    render() {
        return (
            <div>
                <Container style={{"marginTop": 20}}>
                    <Card>
                        <CardContent>
                            <Typography>Add Marshall</Typography>
                            <TextField fullWidth id="email" onChange={this.handleChange} label="Email" type="email" />
                            <TextField fullWidth id="password" onChange={this.handleChange} label="Password" type="password" />
                        </CardContent>
                        <CardActions className="right">
                            <Link to="/"><Button color="secondary">Cancel</Button></Link>
                            <Button onClick={this.handleSubmit} color="primary">Submit</Button>
                        </CardActions>
                    </Card>
                </Container>
            </div>
        )
    }
}

// const mapStateToProps = (state) => {
//     return {

//     }
// }

const mapDispatchToProps = (dispatch) => {
    return {
        addCarpMarshall: (creds) => dispatch(addCarpMarshall(creds))
    }
}

export default compose(
    connect(null, mapDispatchToProps)
)(AddCarpMarshall)
